.CustomerReviewItemCard {
	background: #f0efeb;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	font-size: 0.9em;
	margin-bottom: 10px;
	position: relative;
}

/* ************************************* MAIN : START : Logo Section ********************************** */
.CustomerReviewItemCard__logo {
	background: #4e678a;
	display: flex;
	justify-self: center;
	width: 50px;
}

.CustomerReviewItemCard__logo svg {
	color: rgb(100, 98, 98);
	color: white;
	height: 100%;
	margin: 0 auto;
	max-width: 35px;
	width: 100%;
}

/* ************************************* MAIN : END : Logo Section ********************************** */

/* ************************************* MAIN : START : Menu Section (Absolute Positioned) ********************************** */
.CustomerReviewItemCard__menu {
	position: absolute;
	right: 10px;
	top: 10px;
}

/* MaterialUI menu are rendered in root and only way we can select it through id we provided for it. */
#CustomerReviewItemCard__menu__menuContent__menu .MuiList-root li {
	font-size: 0.85em;
}

.CustomerReviewItemCard__menu__menuContent__menuIcon {
	align-items: center;
	display: flex;
}

.CustomerReviewItemCard__menu__menuContent__menuIcon__progress {
	margin-right: 5px;
}

.CustomerReviewItemCard__menu__menuContent__menuIcon__icon {
	cursor: pointer;
}

/* ************************************* MAIN : END : Menu Section (Absolute Positioned) ********************************** */

/* ************************************* MAIN : START :  Details Section ********************************** */
.CustomerReviewItemCard__details {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 15px 20px;
}

/* **************** SUBSECTION : START : Review Specific Details (General) **************** */
.CustomerReviewItemCard__details__reviewGeneralData > div {
	padding-bottom: 5px;
}

.CustomerReviewItemCard__details__reviewGeneralData__title {
	font-weight: bold;
}

.CustomerReviewItemCard__details__reviewGeneralData__reviewText {
	font-style: italic;
}

.CustomerReviewItemCard__details__reviewGeneralData__time {
	margin-left: auto;
	white-space: nowrap;
}

/* **************** SUBSECTION : END : Review Specific Details (General) **************** */

/* **************** SUBSECTION : START : Review Admin Specific Details (Admin) **************** */
.CustomerReviewItemCard__details__adminData {
	margin-top: 15px;
}

.CustomerReviewItemCard__details__adminData span {
	align-items: center;
	display: inline-flex;
}

.CustomerReviewItemCard__details__adminData strong {
	padding-right: 5px;
}
.CustomerReviewItemCard__details__adminData__reviewType {
	margin-bottom: 5px;
}

/* **************** SUBSECTION : END : Review Admin Specific Details (Admin) **************** */

.CustomerReviewItemCard__details__bottom {
	align-items: flex-end;
	color: rgb(73, 71, 71);
	display: flex;
	height: 100%;
}

/* **************** SUBSECTION : START : Customer Specific Details **************** */

.CustomerReviewItemCard__details__customerDetails {
	margin-bottom: -5px;
	margin-top: 15px;
}

.CustomerReviewItemCard__details__customerDetails span {
	align-items: center;
	display: inline-flex;
	margin-right: 10px;
}

.CustomerReviewItemCard__details__customerDetails span svg {
	font-size: 20px;
	margin-right: 5px;
}

/* **************** SUBSECTION : END: Customer Specific  Details **************** */

/* ************************************* MAIN : END : Details Section ********************************** */

/* ************************************* MAIN : START : Points Section ********************************** */
.CustomerReviewItemCard__points {
	align-items: center;
	display: flex;
	font-size: 0.8em;
	font-weight: bold;
	justify-content: center;
	min-width: 50px;
	padding: 15px;
	text-align: center;
}

.CustomerReviewItemCard__points__pointValue {
	align-items: center;
	background: #294a79;
	border-radius: 50%;
	color: white;
	display: flex;
	height: 60px;
	justify-content: center;
	margin: 10px;
	width: 60px;
}

/* ************************************* MAIN : END : Points Section ********************************** */

/* ************************************* MAIN : START : Media Queries ********************************** */

@media only screen and (max-width: 600px) {
	.CustomerReviewItemCard {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard__details__bottom {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard__details__customerDetails {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard__details__reviewGeneralData__time {
		margin: 0;
		margin-top: 15px;
	}
}

@media only screen and (max-width: 500px) {
	.CustomerReviewItemCard {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard > div {
		flex-basis: 100%;
	}

	.CustomerReviewItemCard__logo {
		padding: 5px 0;
		text-align: center;
	}

	.CustomerReviewItemCard__menu__menuContent svg {
		color: white;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */
