/* ********************************************************** Signup Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.SignupEmailConfFormControls__inputs input,
.SignupEmailConfFormControls__inputs .MuiInputBase-root {
	background: white;
}

/* Adding bottom margin to every MaterialUI Input */
.SignupEmailConfFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************************************************** Signup Control Header ********************************************************** */
.SignupEmailConfFormControls__header {
	margin-bottom: 20px;
}

.SignupEmailConfFormControls__header p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.SignupEmailConfFormControls__header a {
	color: rgb(36, 192, 109);
}

.SignupEmailConfFormControls__header span {
	font-weight: bold;
}

.SignupEmailConfFormControls__header__changeEmail {
	margin-top: -10px;
}

/* ********************************************************** Signup Control Footer ********************************************************** */

/* ********************** SUB : Signup Control Footer - Signup Button ********************** */
.SignupEmailConfFormControls__footer__signupButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.SignupEmailConfFormControls__footer__signupButton[disabled] {
	background: rgb(157, 192, 147);
}

.SignupEmailConfFormControls__footer__signupButton:hover {
	transform: scale(1.01);
}

.SignupEmailConfFormControls__footer__signupButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.SignupEmailConfFormControls input,
	.SignupEmailConfFormControls__footer__signupButton {
		font-size: 0.8em;
	}
}
