.HomePage {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	text-align: center;
}

.HomePage__header img {
	max-width: 200px;
}

.HomePage__header h1 {
	color: rgb(35, 88, 112);
	font-family: 'Oswald', sans-serif;
	font-size: 3em;
	margin: 0;
}

.HomePage__buttons {
	display: flex;
}

.HomePage__buttons > a {
	border-radius: 4px;
	color: white;
	display: block;
	flex: 1;
	font-weight: 500;
	margin: 20px 10px;
	padding: 15px;
	text-decoration: none;
	text-transform: uppercase;
}

.HomePage__buttons > a:hover {
	transform: scale(1.02);
}

.HomePage__buttons__login {
	background: rgb(59, 93, 187);
}

.HomePage__buttons__signup {
	background: rgb(214, 95, 95);
}
