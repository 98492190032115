/* ********************************************************** Signup Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.SignupFormControls__inputs input,
.SignupFormControls__inputs .MuiInputBase-root,
.SignupFormControls__inputs .MuiSelect-root {
	background: white;
}

.SignupFormControls__flashMessage {
	background-color: rgba(171, 211, 125, 0.795);
	border-radius: 4px;
	font-size: 0.8em;
	padding: 10px 20px;
}

/* ********************** SUB : Signup Control Inputs - Grid Nested Input Bug Fix ********************** */

/* Fixing Input Controls Spacing Problem with Grid Items in Mobile Resolutions */
.SignupFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

.SignupFormControls__inputs .MuiGrid-spacing-xs-1 > .MuiGrid-item {
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}

.SignupFormControls__inputs .MuiGrid-spacing-xs-1 {
	margin-bottom: 0 !important;
	margin-top: 0 !important;
}

.SignupFormControls__inputs__countrySelect {
	position: relative;
}

/* ********************** SUB : Signup Control Inputs - Customized Select Placeholder ********************** */

/* Adding customized Placeholder for Country Selector to match with MateriaUI. As MaterialUI Select Placeholder mess things up. */
.SignupFormControls__inputs__countrySelect__customPlaceholder {
	bottom: 0;
	color: #aaa;
	left: 45px;
	position: absolute;
	top: 0;
	transform: translateY(30%);
	z-index: 100;
}

/* ********************************************************** Signup Control Footer ********************************************************** */
.SignupFormControls__footer p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.SignupFormControls__footer a {
	color: rgb(36, 192, 109);
	padding: 0 5px;
	text-decoration: none;
}

.SignupFormControls__footer a:hover {
	text-decoration: underline;
}

/* ********************** SUB : Signup Control Footer - Signup Button ********************** */
.SignupFormControls__footer__signupButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.SignupFormControls__footer__signupButton[disabled] {
	background: rgb(157, 192, 147);
}

.SignupFormControls__footer__signupButton:hover {
	transform: scale(1.01);
}

.SignupFormControls__footer__signupButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

.SignupFormControls__footer__acknowledgeParagraph {
	color: gray !important;
	text-align: center;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.SignupFormControls input,
	.SignupFormControls .MuiSelect-root,
	.SignupFormControls__inputs__countrySelect__customPlaceholder,
	.SignupFormControls__footer__signupButton {
		font-size: 0.8em;
	}
}
