.CustomLabeledFormField {
	align-items: stretch;
	background: rgba(209, 178, 178, 0.467);
	background: #2646532c;
	border-radius: 0 20px 20px 0;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	margin: 5px 0;
}

.CustomLabeledFormField__label {
	align-items: center;
	background: #264653;
	color: white;
	display: flex;
	font-weight: 500;
	margin-right: 10px;
	min-width: 200px;
	padding: 10px 15px;
}

.CustomLabeledFormField__content {
	align-self: center;
	flex: 1;
	padding: 10px 15px;
}

/* ************************************* MAIN : START : CustomLabeledFormField's "--sub" Suffix Overrides ********************************** */
.CustomLabeledFormField--sub {
	background: #457b9d2c;
}

.CustomLabeledFormField__label--sub {
	background: #457b9d;
}

/* ************************************* MAIN : END : CustomLabeledFormField's "--sub" Suffix Overrides ********************************** */

/* ************************************* MAIN : START : Media Queries  ************************************* */
@media only screen and (max-width: 800px) {
	.CustomLabeledFormField {
		flex-wrap: wrap;
	}

	.CustomLabeledFormField--sub {
		border-radius: 0;
		margin-bottom: 10px;
	}

	.CustomLabeledFormField__label--sub {
		min-width: unset;
	}

	.CustomLabeledFormField__label--sub,
	.CustomLabeledFormField__content--sub {
		border-radius: 0;
		flex-basis: 100%;
		margin-right: 0;
	}
}

@media only screen and (max-width: 600px) {
	.CustomLabeledFormField {
		border-radius: 0;
	}

	.CustomLabeledFormField--sub {
		margin-left: 15px;
	}

	.CustomLabeledFormField__label {
		min-width: unset;
	}

	.CustomLabeledFormField__label,
	.CustomLabeledFormField__content {
		border-radius: 0;
		flex-basis: 100%;
		margin-right: 0;
	}
}

/* ************************************* MAIN : END : Media Queries /* ************************************* */
