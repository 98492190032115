/* ************************************* MAIN : START : LoyaltyItems Showing Section ********************************** */
.MyLoyaltyAwardsPage__body__loyaltyAwards {
	display: flex;
	height: 100%;
}

.MyLoyaltyAwardsPage__body__loyaltyAwards__awardItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : LoyaltyItems Showing Section ********************************** */
