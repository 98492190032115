@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=Cairo:wght@200;400&display=swap');

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;

	/* IMPORTANT NOTE : Below two rules are added as TEMPORARY fix for MaterialUI Scroll Disappear bug.
											Ex. When ever overlay component render (Material UI Popover, Select, .....) scrollbar disappear and appear uglily.
	*/
	overflow: auto !important;
	padding-right: 0 !important;
}

/* Global CSS Variables */
:root {
	--pageBackgroundColor: rgb(238, 241, 241);
	--containerBackgroundColor: rgb(243, 234, 234);
}
