/* ************************************* MAIN : START : AddReviewModalForm MaterialUI Inputs  ********************************** */

/* <FormLabel/> Components */
.MuiFormLabel-root {
	font-family: 'Cairo', sans-serif !important;
	margin-right: 10px;
}

/* Making all MaterialUI Input background whitish */
.AddReviewModalFormControls__inputs input,
.AddReviewModalFormControls__inputs .MuiInputBase-root {
	background: #f5f3f3;
	font-size: 0.9em;
}

/* ************************************* MAIN : END : AddReviewModalForm MaterialUI Inputs  ********************************** */

/* ************************************* MAIN : START : AddReviewModalForm All Inputs  ********************************** */
.AddReviewModalFormControls__inputs > div {
	margin-bottom: 15px;
}

.AddReviewModalFormControls__inputs__starValue {
	display: flex;
}

/* ************************************* MAIN : END: AddReviewModalForm All Inputs  ********************************** */

/* ************************************* MAIN : START : AddReviewModalForm Footer  ********************************** */
.AddReviewModalFormControls__footer {
	margin-top: 15px;
}

.AddReviewModalFormControls__footer__buttons {
	display: flex;
	justify-content: flex-end;
}

/* ************************************* MAIN : END : AddReviewModalForm Footer  ********************************** */
