/* ********************************************************** LinkUserToBusinessEmailEntryFormControls  Inputs Controls********************************************************** */

/* Making all MaterialUI Input background white */
.LinkUserToBusinessEmailEntryFormControls__inputs input,
.LinkUserToBusinessEmailEntryFormControls__inputs .MuiInputBase-root {
	background: white;
}

/* Adding bottom margin to every MaterialUI Input */
.LinkUserToBusinessEmailEntryFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************************************************** LinkUserToBusinessEmailEntryFormControls  Header ********************************************************** */
.LinkUserToBusinessEmailEntryFormControls__header {
	margin-bottom: 20px;
}

.LinkUserToBusinessEmailEntryFormControls__header p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

/* ********************************************************** LinkUserToBusinessEmailEntryFormControls  Footer ********************************************************** */

/* ********************** SUB : LinkUserToBusinessEmailEntryFormControlsFooter - Button ********************** */
.LinkUserToBusinessEmailEntryFormControls__footer__button {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.LinkUserToBusinessEmailEntryFormControls__footer__button[disabled] {
	background: rgb(157, 192, 147);
}

.LinkUserToBusinessEmailEntryFormControls__footer__button:hover {
	transform: scale(1.01);
}

.LinkUserToBusinessEmailEntryFormControls__footer__button__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.LinkUserToBusinessEmailEntryFormControls input,
	.LinkUserToBusinessEmailEntryFormControls__footer__LinkUserToBusinessEmailEntryFormControlsButton {
		font-size: 0.8em;
	}
}
