.DashboardPage {
	background: var(--pageBackgroundColor);
	display: flex;
	min-height: 100vh;
}

.DashboardPage > div {
	width: 100%;
}

.DashboardPage__body {
	display: flex;
	height: 100%;
	padding-top: 64px;
}

/* ************************************* MAIN : START : Dashboard Loader Screen ********************************** */
.DashboardPageLoader {
	align-items: center;
	display: flex;
	justify-content: center;
}

.DashboardPageLoader img {
	margin: 0 auto;
	max-width: 500px;
	padding: 20px;
	width: 100%;
}

/* ************************************* MAIN : END : Dashboard Loader Screen ********************************** */

/* ************************************* MAIN : START : Dashboard MainMenu ********************************** */
.DashboardPage__mainMenu {
	background: rgb(221, 212, 212);
	height: 100%;
}

.DashboardPage__mainMenu__header {
	background: var(--containerBackgroundColor);
	margin-bottom: -9px;
	padding: 10px 20px 20px 15px;
}

.DashboardPage__mainMenu__header img {
	max-width: 100%;
	padding: 10px;
}

.DashboardPage__mainMenu__header h1 {
	font-family: 'Oswald', sans-serif;
	font-size: 2.3em;
	margin: 0;
	margin-top: -10px;
	text-align: center;
}

.DashboardPage__mainMenu__links a {
	color: inherit;
	text-decoration: none;
}

/* ************************************* MAIN : END : Dashboard MainMenu ********************************** */

.DashboardPage__selectedPageContent {
	flex: 1;
	padding: 20px;
}
