/* ************************************* MAIN : START : Schemes Section ********************************** */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup {
	margin-bottom: 10px;
}

/* Accordions */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordion-root {
	background: #fffafa33;
}

/* Accordions top header content holder.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionSummary-content {
	align-items: center;
	display: flex;
}

/* Accordions top header title.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup__title {
	font-style: italic;
	font-weight: bold;
}

/* Accordions top header switch button.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionSummary-content
	.MuiFormControlLabel-root {
	margin-left: auto;
}

/* Accordions top header switch button label.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionSummary-content
	.MuiFormControlLabel-root
	.MuiTypography-root {
	font-size: 0.8em;
}

/* Accordions Content  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionDetails-root {
	display: block;
}

/* ************************************* MAIN : END : Schemes Section ********************************** */
