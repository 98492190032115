@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200&family=Cairo:wght@200;400&display=swap);
html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;

	/* IMPORTANT NOTE : Below two rules are added as TEMPORARY fix for MaterialUI Scroll Disappear bug.
											Ex. When ever overlay component render (Material UI Popover, Select, .....) scrollbar disappear and appear uglily.
	*/
	overflow: auto !important;
	padding-right: 0 !important;
}

/* Global CSS Variables */
:root {
	--pageBackgroundColor: rgb(238, 241, 241);
	--containerBackgroundColor: rgb(243, 234, 234);
}

.PageLoader {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	text-align: center;
}

.PageLoader__info {
	align-items: center;
	display: flex;
}

.PageLoader__info__error {
	color: red;
}

.PageLoader__info__error__buttons {
	margin-top: 15px;
}

.CustomerCheckInItemCard {
	background: #f0efeb;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	font-size: 0.9em;
	margin-bottom: 10px;
}

/* ************************************* MAIN : START : Logo Section ********************************** */
.CustomerCheckInItemCard__logo {
	background: #4e678a;
	display: flex;
	justify-self: center;
	width: 50px;
}

.CustomerCheckInItemCard__logo svg {
	color: rgb(100, 98, 98);
	color: white;
	height: 100%;
	margin: 0 auto;
	max-width: 50px;
	width: 100%;
}

/* ************************************* MAIN : END : Logo Section ********************************** */

/* ************************************* MAIN : START :  Details Section ********************************** */
.CustomerCheckInItemCard__details {
	display: flex;
	flex: 1 1;
	flex-direction: column;
	padding: 15px 20px;
}

/* **************** SUBSECTION : START : CheckIn Specific Details **************** */
.CustomerCheckInItemCard__details > div {
	padding-bottom: 5px;
}

.CustomerCheckInItemCard__details__title {
	font-weight: bold;
}

.CustomerCheckInItemCard__details__desc {
	font-style: italic;
}

.CustomerCheckInItemCard__details__time {
	margin-left: auto;
	white-space: nowrap;
}

/* **************** SUBSECTION : END : CheckIn Specific Details **************** */

.CustomerCheckInItemCard__details__bottom {
	align-items: flex-end;
	color: rgb(73, 71, 71);
	display: flex;
	height: 100%;
}

/* **************** SUBSECTION : START : Customer Specific Details **************** */

.CustomerCheckInItemCard__details__customerDetails {
	align-items: center;
	display: flex;
	margin-bottom: -5px;
}

.CustomerCheckInItemCard__details__customerDetails__name {
	white-space: nowrap;
}

.CustomerCheckInItemCard__details__customerDetails span {
	align-items: center;
	display: inline-flex;
	margin-right: 10px;
}

.CustomerCheckInItemCard__details__customerDetails span svg {
	font-size: 20px;
	margin-right: 5px;
}

/* **************** SUBSECTION : END: Customer Specific  Details **************** */

/* ************************************* MAIN : END : Details Section ********************************** */

/* ************************************* MAIN : START : Points Section ********************************** */
.CustomerCheckInItemCard__points {
	align-items: center;
	display: flex;
	font-size: 0.8em;
	font-weight: bold;
	justify-content: center;
	min-width: 50px;
	padding: 15px;
	text-align: center;
}

.CustomerCheckInItemCard__points__pointValue {
	align-items: center;
	background: #294a79;
	border-radius: 50%;
	color: white;
	display: flex;
	height: 60px;
	justify-content: center;
	margin: 10px;
	width: 60px;
}

.CustomerCheckInItemCard__points__totalUserPointValue {
	padding: 5px;
}

/* ************************************* MAIN : END : Points Section ********************************** */

/* ************************************* MAIN : START : Media Queries ********************************** */

@media only screen and (max-width: 600px) {
	.CustomerCheckInItemCard {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard__details__bottom {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard__details__customerDetails {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard__details__time {
		margin: 0;
		margin-top: 15px;
	}
}

@media only screen and (max-width: 500px) {
	.CustomerCheckInItemCard {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard > div {
		flex-basis: 100%;
	}

	.CustomerCheckInItemCard__logo {
		padding: 5px 0;
		text-align: center;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */

.DashboardSubInfoSection {
	align-items: center;
	color: gray;
	display: flex;
	flex: 1 1;
	font-size: 0.8em;
	height: 100%;
	justify-content: center;
	margin: 0 auto;
	text-align: center;
	width: 100%;
}

.DashboardSubInfoSection svg {
	font-size: 100px;
}

.LocationSelector__selectorsWrapper__headerInfo {
	font-weight: 500;
}

.LocationSelector__selectorsWrapper__selectors {
	display: flex;
	font-size: 0.9em;
	margin-top: 15px;
}

.LocationSelector__selectorsWrapper__selectors > div {
	flex: 1 1;
	margin-right: 5px;
}

/* ************************************* MAIN : START : Media Queries********************************** */
@media only screen and (max-width: 600px) {
	.LocationSelector__selectorsWrapper__selectors {
		flex-wrap: wrap;
	}

	.LocationSelector__selectorsWrapper__selectors > div {
		flex-basis: 100%;
		margin-bottom: 5px;
		margin-right: 0;
	}

	.LocationSelector__selectorsWrapper__selectors button {
		margin-left: auto;
	}
}

/* ************************************* MAIN : END : Media Queries********************************** */

.CustomerCheckInsPage__body__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : CheckInItems Showing Section ********************************** */
.CustomerCheckInsPage__body__checkIns {
	display: flex;
	height: 100%;
}

.CustomerCheckInsPage__body__checkIns__checkInItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : CheckInItems Showing Section ********************************** */

.CustomerLoyaltyAwardsItemCard {
	background: #f0efeb;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	font-size: 0.9em;
	margin-bottom: 10px;
}

/* ************************************* MAIN : START : Logo Section ********************************** */
.CustomerLoyaltyAwardsItemCard__logo {
	background: #4e678a;
	display: flex;
	justify-self: center;
	width: 50px;
}

.CustomerLoyaltyAwardsItemCard__logo svg {
	color: white;
	height: 100%;
	margin: 0 auto;
	max-width: 35px;
	width: 100%;
}

/* ************************************* MAIN : END : Logo Section ********************************** */

/* ************************************* MAIN : START :  Details Section ********************************** */
.CustomerLoyaltyAwardsItemCard__details {
	display: flex;
	flex: 1 1;
	flex-direction: column;
	padding: 15px 20px;
}

/* **************** SUBSECTION : START : Award Specific Details **************** */
.CustomerLoyaltyAwardsItemCard__details > div {
	padding-bottom: 5px;
}

.CustomerLoyaltyAwardsItemCard__details__title {
	font-weight: bold;
}

.CustomerLoyaltyAwardsItemCard__details__schemeType,
.CustomerLoyaltyAwardsItemCard__details__desc {
	font-style: italic;
}

.CustomerLoyaltyAwardsItemCard__details__invoiceDetails {
	font-style: italic;
	margin-top: 10px;
}

.CustomerLoyaltyAwardsItemCard__details__time {
	margin-left: auto;
	white-space: nowrap;
}

/* **************** SUBSECTION : END : Award Specific  Details **************** */

.CustomerLoyaltyAwardsItemCard__details__bottom {
	align-items: flex-end;
	color: rgb(73, 71, 71);
	display: flex;
	height: 100%;
}

/* **************** SUBSECTION : START : Customer Specific Details **************** */

.CustomerLoyaltyAwardsItemCard__details__customerDetails {
	align-items: center;
	display: flex;
	margin-bottom: -5px;
}

.CustomerLoyaltyAwardsItemCard__details__customerDetails__name {
	white-space: nowrap;
}

.CustomerLoyaltyAwardsItemCard__details__customerDetails span {
	align-items: center;
	display: inline-flex;
	margin-right: 10px;
}

.CustomerLoyaltyAwardsItemCard__details__customerDetails span svg {
	font-size: 20px;
	margin-right: 5px;
}

/* **************** SUBSECTION : END: Customer Specific  Details **************** */

/* ************************************* MAIN : END : Details Section ********************************** */

/* ************************************* MAIN : START : Points Section ********************************** */
.CustomerLoyaltyAwardsItemCard__points {
	align-items: center;
	display: flex;
	font-size: 0.8em;
	font-weight: bold;
	justify-content: center;
	min-width: 50px;
	padding: 15px;
	text-align: center;
}

.CustomerLoyaltyAwardsItemCard__points__pointValue {
	align-items: center;
	background: #294a79;
	border-radius: 50%;
	color: white;
	display: flex;
	height: 60px;
	justify-content: center;
	margin: 10px;
	width: 60px;
}

.CustomerLoyaltyAwardsItemCard__points__totalUserPointValue {
	padding: 5px;
}

/* ************************************* MAIN : END : Points Section ********************************** */

/* ************************************* MAIN : START : Media Queries ********************************** */

@media only screen and (max-width: 600px) {
	.CustomerLoyaltyAwardsItemCard {
		flex-wrap: wrap;
	}

	.CustomerLoyaltyAwardsItemCard__details__bottom {
		flex-wrap: wrap;
	}

	.CustomerLoyaltyAwardsItemCard__details__customerDetails {
		flex-wrap: wrap;
	}

	.CustomerLoyaltyAwardsItemCard__details__time {
		margin: 0;
		margin-top: 15px;
	}
}

@media only screen and (max-width: 500px) {
	.CustomerLoyaltyAwardsItemCard {
		flex-wrap: wrap;
	}

	.CustomerLoyaltyAwardsItemCard > div {
		flex-basis: 100%;
	}

	.CustomerLoyaltyAwardsItemCard__logo {
		padding: 5px 0;
		text-align: center;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */

.CustomerLoyaltyAwardsPage__body__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : AwardsItems Showing Section ********************************** */
.CustomerLoyaltyAwardsPage__body__loyaltyAwards {
	display: flex;
	height: 100%;
}

.CustomerLoyaltyAwardsPage__body__loyaltyAwards__awardsItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : AwardsItems Showing Section ********************************** */

.CustomerReviewItemCard {
	background: #f0efeb;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	font-size: 0.9em;
	margin-bottom: 10px;
	position: relative;
}

/* ************************************* MAIN : START : Logo Section ********************************** */
.CustomerReviewItemCard__logo {
	background: #4e678a;
	display: flex;
	justify-self: center;
	width: 50px;
}

.CustomerReviewItemCard__logo svg {
	color: rgb(100, 98, 98);
	color: white;
	height: 100%;
	margin: 0 auto;
	max-width: 35px;
	width: 100%;
}

/* ************************************* MAIN : END : Logo Section ********************************** */

/* ************************************* MAIN : START : Menu Section (Absolute Positioned) ********************************** */
.CustomerReviewItemCard__menu {
	position: absolute;
	right: 10px;
	top: 10px;
}

/* MaterialUI menu are rendered in root and only way we can select it through id we provided for it. */
#CustomerReviewItemCard__menu__menuContent__menu .MuiList-root li {
	font-size: 0.85em;
}

.CustomerReviewItemCard__menu__menuContent__menuIcon {
	align-items: center;
	display: flex;
}

.CustomerReviewItemCard__menu__menuContent__menuIcon__progress {
	margin-right: 5px;
}

.CustomerReviewItemCard__menu__menuContent__menuIcon__icon {
	cursor: pointer;
}

/* ************************************* MAIN : END : Menu Section (Absolute Positioned) ********************************** */

/* ************************************* MAIN : START :  Details Section ********************************** */
.CustomerReviewItemCard__details {
	display: flex;
	flex: 1 1;
	flex-direction: column;
	padding: 15px 20px;
}

/* **************** SUBSECTION : START : Review Specific Details (General) **************** */
.CustomerReviewItemCard__details__reviewGeneralData > div {
	padding-bottom: 5px;
}

.CustomerReviewItemCard__details__reviewGeneralData__title {
	font-weight: bold;
}

.CustomerReviewItemCard__details__reviewGeneralData__reviewText {
	font-style: italic;
}

.CustomerReviewItemCard__details__reviewGeneralData__time {
	margin-left: auto;
	white-space: nowrap;
}

/* **************** SUBSECTION : END : Review Specific Details (General) **************** */

/* **************** SUBSECTION : START : Review Admin Specific Details (Admin) **************** */
.CustomerReviewItemCard__details__adminData {
	margin-top: 15px;
}

.CustomerReviewItemCard__details__adminData span {
	align-items: center;
	display: inline-flex;
}

.CustomerReviewItemCard__details__adminData strong {
	padding-right: 5px;
}
.CustomerReviewItemCard__details__adminData__reviewType {
	margin-bottom: 5px;
}

/* **************** SUBSECTION : END : Review Admin Specific Details (Admin) **************** */

.CustomerReviewItemCard__details__bottom {
	align-items: flex-end;
	color: rgb(73, 71, 71);
	display: flex;
	height: 100%;
}

/* **************** SUBSECTION : START : Customer Specific Details **************** */

.CustomerReviewItemCard__details__customerDetails {
	margin-bottom: -5px;
	margin-top: 15px;
}

.CustomerReviewItemCard__details__customerDetails span {
	align-items: center;
	display: inline-flex;
	margin-right: 10px;
}

.CustomerReviewItemCard__details__customerDetails span svg {
	font-size: 20px;
	margin-right: 5px;
}

/* **************** SUBSECTION : END: Customer Specific  Details **************** */

/* ************************************* MAIN : END : Details Section ********************************** */

/* ************************************* MAIN : START : Points Section ********************************** */
.CustomerReviewItemCard__points {
	align-items: center;
	display: flex;
	font-size: 0.8em;
	font-weight: bold;
	justify-content: center;
	min-width: 50px;
	padding: 15px;
	text-align: center;
}

.CustomerReviewItemCard__points__pointValue {
	align-items: center;
	background: #294a79;
	border-radius: 50%;
	color: white;
	display: flex;
	height: 60px;
	justify-content: center;
	margin: 10px;
	width: 60px;
}

/* ************************************* MAIN : END : Points Section ********************************** */

/* ************************************* MAIN : START : Media Queries ********************************** */

@media only screen and (max-width: 600px) {
	.CustomerReviewItemCard {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard__details__bottom {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard__details__customerDetails {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard__details__reviewGeneralData__time {
		margin: 0;
		margin-top: 15px;
	}
}

@media only screen and (max-width: 500px) {
	.CustomerReviewItemCard {
		flex-wrap: wrap;
	}

	.CustomerReviewItemCard > div {
		flex-basis: 100%;
	}

	.CustomerReviewItemCard__logo {
		padding: 5px 0;
		text-align: center;
	}

	.CustomerReviewItemCard__menu__menuContent svg {
		color: white;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */

.CustomerReviewsPage__body__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : ReviewItems Showing Section ********************************** */
.CustomerReviewsPage__body__reviews {
	display: flex;
	height: 100%;
}

.CustomerReviewsPage__body__reviews__reviewItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : ReviewItems Showing Section ********************************** */

.DashboardHomeBis {
	height: 100%;
}

/* ************************************* MAIN : START : DashboardHome Header Items  ************************************* */

.DashboardHomeBis__header {
	display: flex;
	margin-bottom: 20px;
	padding: 10px 0;
}

.DashboardHomeBis__header__right {
	align-items: center;
	display: none;

	/* display: flex; */
	margin-left: auto;
}

/* ***************** SUB : START : UserMenu Section ***************** */

.DashboardHomeBis__header__userMenu {
	margin-left: 10px;
}

.DashboardHomeBis__header__userMenu svg {
	cursor: pointer;
	font-size: 50px;
}

/* ***************** SUB : END : UserMenu Section ***************** */

/* ************************************* MAIN : END : DashboardHome Header Items  ************************************* */

/* ************************************* MAIN : START : DashboardHome Body  ************************************* */

/* ***************** SUB : START : Welcome Section ***************** */
.DashboardHomeBis__body__welcome {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	margin-top: 200px;
	text-align: center;
}

.DashboardHomeBis img {
	max-width: 300px;
}

.DashboardHomeBis h1 {
	color: rgb(66, 57, 57);
	font-size: 2.4em;
}

.DashboardHomeBis h2 {
	color: gray;
	font-size: 0.9em;
	font-weight: normal;
	margin: 0;
	margin-top: -15px;
}

/* ***************** SUB : END : TotalPoints Section ***************** */

/* ************************************* MAIN : END : DashboardHome Body  ************************************* */

.CustomLabeledFormField {
	align-items: stretch;
	background: rgba(209, 178, 178, 0.467);
	background: #2646532c;
	border-radius: 0 20px 20px 0;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	margin: 5px 0;
}

.CustomLabeledFormField__label {
	align-items: center;
	background: #264653;
	color: white;
	display: flex;
	font-weight: 500;
	margin-right: 10px;
	min-width: 200px;
	padding: 10px 15px;
}

.CustomLabeledFormField__content {
	align-self: center;
	flex: 1 1;
	padding: 10px 15px;
}

/* ************************************* MAIN : START : CustomLabeledFormField's "--sub" Suffix Overrides ********************************** */
.CustomLabeledFormField--sub {
	background: #457b9d2c;
}

.CustomLabeledFormField__label--sub {
	background: #457b9d;
}

/* ************************************* MAIN : END : CustomLabeledFormField's "--sub" Suffix Overrides ********************************** */

/* ************************************* MAIN : START : Media Queries  ************************************* */
@media only screen and (max-width: 800px) {
	.CustomLabeledFormField {
		flex-wrap: wrap;
	}

	.CustomLabeledFormField--sub {
		border-radius: 0;
		margin-bottom: 10px;
	}

	.CustomLabeledFormField__label--sub {
		min-width: unset;
	}

	.CustomLabeledFormField__label--sub,
	.CustomLabeledFormField__content--sub {
		border-radius: 0;
		flex-basis: 100%;
		margin-right: 0;
	}
}

@media only screen and (max-width: 600px) {
	.CustomLabeledFormField {
		border-radius: 0;
	}

	.CustomLabeledFormField--sub {
		margin-left: 15px;
	}

	.CustomLabeledFormField__label {
		min-width: unset;
	}

	.CustomLabeledFormField__label,
	.CustomLabeledFormField__content {
		border-radius: 0;
		flex-basis: 100%;
		margin-right: 0;
	}
}

/* ************************************* MAIN : END : Media Queries /* ************************************* */

/* ************************************* MAIN : START : Schemes Section ********************************** */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup {
	margin-bottom: 10px;
}

/* Accordions */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordion-root {
	background: #fffafa33;
}

/* Accordions top header content holder.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionSummary-content {
	align-items: center;
	display: flex;
}

/* Accordions top header title.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup__title {
	font-style: italic;
	font-weight: bold;
}

/* Accordions top header switch button.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionSummary-content
	.MuiFormControlLabel-root {
	margin-left: auto;
}

/* Accordions top header switch button label.  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionSummary-content
	.MuiFormControlLabel-root
	.MuiTypography-root {
	font-size: 0.8em;
}

/* Accordions Content  */
.LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup
	.MuiAccordionDetails-root {
	display: block;
}

/* ************************************* MAIN : END : Schemes Section ********************************** */

.LoyaltySchemeSettingsPage__body__formikContainer {
	display: flex;
	flex: 1 1;
	flex-direction: column;
}

.LoyaltySchemeSettingsPage__body__formikContainer__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : No Settings View ********************************** */

.LoyaltySchemeSettingsPage__body__formikContainer__noSettings__selectedItem {
	font-weight: 600;
	margin-top: 5px;
}

.LoyaltySchemeSettingsPage__body__formikContainer__noSettings svg {
	font-size: 75px;
}

.LoyaltySchemeSettingsPage__body__formikContainer__noSettings__addButton {
	cursor: pointer;
}

/* ************************************* MAIN : END : No Settings View ********************************** */

/* ************************************* MAIN : START : Add/Update Settings Form ********************************** */
.LoyaltySchemeSettingsPage__body__settingsForm {
	font-size: 0.9em;
	margin: 0 auto;
	max-width: 600px;
	padding-top: 20px;
}

.LoyaltySchemeSettingsPage__body__settingsForm .MuiInputBase-root {
	font-size: 0.9em;
}

.LoyaltySchemeSettingsPage__body__settingsForm__header {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}

.LoyaltySchemeSettingsPage__body__settingsForm__header button {
	margin-left: 5px;
}

.LoyaltySchemeSettingsPage__body__settingsForm__formControls__description {
	color: rgb(116, 109, 109);
	font-size: 0.95em;
	font-weight: 600;
	padding-bottom: 5px;
}

/* ************************************* MAIN : END : Settings Form ********************************** */

/* ************************************* MAIN : START : QR Code ********************************** */
.QrCodeGeneratorPage__qrCodeWrapper {
	align-items: center;
	display: flex;
	flex: 1 1;
	justify-content: center;
	text-align: center;
}

.QrCodeGeneratorPage__qrCodeWrapper__qr canvas {
	max-width: 500px;
	width: 100%;
}

.QrCodeGeneratorPage__qrCodeWrapper__buttons {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.QrCodeGeneratorPage__qrCodeWrapper__buttons__download {
	color: #3f51b5;
	cursor: pointer;
	font-weight: bold;
	text-align: center;
}

.QrCodeGeneratorPage__qrCodeWrapper__buttons__download svg {
	font-size: 50px;
}

/* ************************************* MAIN : END : QR Code ********************************** */

/* ************************************* MAIN : START : Specific Loading/Error Cases Showing ********************************** */
.QrCodeGeneratorPage__caseHandling {
	align-items: center;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	justify-content: center;
}

.QrCodeGeneratorPage__caseHandling:empty {
	flex: 0 1 !important;
}

.QrCodeGeneratorPage__caseHandling__getStarted svg {
	font-size: 200px;
}

/* ************************************* MAIN : END : Specific Loading/Error Cases Showing ********************************** */

.CustomerDetailsPageFormControls .MuiFormControl-root input,
.CustomerDetailsPageFormControls .MuiFormControl-root .MuiSelect-root {
	font-size: 0.85em;
}

/* ************************************* MAIN : START : Groups of "<CustomerDetailsPageInfoItem/>" ********************************** */
.CustomerDetailsPageFormControls__infoContainer {
	margin: 0 auto;
}

.CustomerDetailsPageFormControls__infoItemsGroup {
	margin-bottom: 40px;
}

.CustomerDetailsPageFormControls__infoItemsGroup--sub {
	margin-bottom: 0;
}

/* ************************************* MAIN : END : Groups of "<CustomerDetailsPageInfoItem/>" ********************************** */

/* ************************************* MAIN : START : Custom Styles for AddressGroups ********************************** */
.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup {
}

.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup
	.MuiAccordion-root {
	background: #fffafa33;
}

.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup
	.MuiAccordionDetails-root {
	display: block;
}

.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup__title {
	font-style: italic;
	font-weight: 500;
}

/* ************************************* MAIN : END : Custom Styles for AddressGroups ********************************** */

.CustomerDetailsPage__body__formikContainer {
	margin: 0 auto;
}

.CustomerDetailsPage__body__formikContainer__form {
	font-size: 0.9em;
}

.CustomerDetailsPage__body__formikContainer__header__options {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}

.DashboardItemCard {
	background: rgb(255, 255, 255);
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.DashboardItemCard__header {
	border-bottom: 1px rgba(128, 128, 128, 0.123) solid;
	color: rgb(107, 106, 106);
	font-family: 'Cairo', sans-serif;
	font-weight: bold;
	padding: 10px 10px;
}

.DashboardItemCard__body {
	flex: 1 1;
	margin: 10px;
}

.DashboardItemCard__footer {
	padding: 0 10px;
}

/* ************************************* MAIN : START : Helper Component : DashboardItemCardFooter ********************************** */

.DashboardItemCardFooter {
	align-items: center;
	display: flex;
	font-size: 0.8em;
	justify-content: flex-end;
}

.DashboardItemCardFooter a {
	color: rgb(38, 103, 133);
	font-weight: bold;
	text-decoration: none;
}

.DashboardItemCardFooter svg {
	color: rgb(38, 103, 133);
}

/* ************************************* MAIN : END : Helper Component : DashboardItemCardFooter ********************************** */

.DashboardHome {
	font-family: 'Cairo', sans-serif;
	height: 100%;
}

/* ************************************* MAIN : START : DashboardHome Header Items  ************************************* */

.DashboardHome__header {
	display: flex;
	margin-bottom: 20px;
	padding: 10px 0;
}

.DashboardHome__header__left {
	align-items: center;
	display: flex;
	font-size: 0.85em;
	font-weight: 500;
}

.DashboardHome__header__right {
	align-items: center;
	display: flex;
	margin-left: auto;
}

/* ***************** SUB : START : CurrentLinkedLocation Section ***************** */
.DashboardHome__header__currentLinkedLocation {
	align-items: center;
	background: #a8dadc;
	border-radius: 0 4px 4px 0;
	display: flex;
	text-align: center;
}

.DashboardHome__header__currentLinkedLocation__title {
	background: #2a9d8f;
	border-radius: 4px 0 0 4px;
	color: white;
	padding: 10px;
	text-align: right;
}

.DashboardHome__header__currentLinkedLocation__value {
	padding: 10px;
}

/* ***************** SUB : END : CurrentLinkedLocation Section ***************** */

/* ***************** SUB : START : TotalPoints Section ***************** */

.DashboardHome__header__totalPoints {
	font-size: 0.85em;
	font-weight: 500;
}

.DashboardHome__header__totalPoints__title {
	background: #2a9d8f;
	border-radius: 4px 0 0 4px;
	color: white;
	padding: 10px;
}

.DashboardHome__header__totalPoints__value {
	background: #a8dadc;
	border-radius: 0 4px 4px 0;
	padding: 10px;
}

/* ***************** SUB : END : TotalPoints Section ***************** */

/* ***************** SUB : START : UserMenu Section ***************** */

.DashboardHome__header__userMenu {
	margin-left: 10px;
}

.DashboardHome__header__userMenu svg {
	cursor: pointer;
	font-size: 50px;
}

/* ***************** SUB : END : UserMenu Section ***************** */

/* ************************************* MAIN : END : DashboardHome Header Items  ************************************* */

.DashboardHome__body__greetings {
	color: #003049;
}

.DashboardHome__body__cards {
	display: grid;
	grid-gap: 40px;
	gap: 40px;
	grid-auto-rows: minmax(300px, auto);
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

/* ************************************* MAIN : START : Media Queries  ************************************* */
@media only screen and (max-width: 600px) {
	.DashboardHome__body__cards {
		grid-template-columns: repeat(1, 1fr);
	}
}

/* ************************************* MAIN : END : Media Queries /* ************************************* */

/* ************************************* MAIN : START : CheckInItems Showing Section ********************************** */
.MyCheckInsPage__body__checkIns {
	display: flex;
	height: 100%;
}

.MyCheckInsPage__body__checkIns__checkInItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : CheckInItems Showing Section ********************************** */

/* ************************************* MAIN : START : LoyaltyItems Showing Section ********************************** */
.MyLoyaltyAwardsPage__body__loyaltyAwards {
	display: flex;
	height: 100%;
}

.MyLoyaltyAwardsPage__body__loyaltyAwards__awardItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : LoyaltyItems Showing Section ********************************** */

/* ************************************* MAIN : START : Total Loyalty Points Showing Section ********************************** */
.MyLoyaltyPointsPage__body__totalLoyaltyPoints {
	display: flex;
	height: 100%;
}

.MyLoyaltyPointsPage__body__totalLoyaltyPoints__totalPointItem {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : Total Loyalty Points Showing Section ********************************** */

/* ************************************* MAIN : START : HELPER COMPONENT : TotalLoyaltyPointItemCard ********************************** */
.TotalLoyaltyPointItemCard {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	margin-bottom: 10px;
	max-width: 400px;
}

.TotalLoyaltyPointItemCard__locationName {
	background: #264653;
	border-radius: 4px 0 0 4px;
	color: white;
	flex: 2 1;
	padding: 15px;
}

.TotalLoyaltyPointItemCard__locationPoints {
	background: #d9dfe1;
	border-radius: 0 4px 4px 0;
	flex: 1 1;
	min-width: 50px;
	padding: 15px;
	text-align: right;
}

/* ************************************* MAIN : END : HELPER COMPONENT : TotalLoyaltyPointItemCard ********************************** */

.BaseModal {
	font-family: 'Cairo', sans-serif;
}

/* ************************************* MAIN : START : Header Close Button Section ********************************** */
.BaseModal__mainContentWrapper__header__closeButton {
	margin-left: auto;
	padding-bottom: 5px;
	text-align: right;
}

.BaseModal__mainContentWrapper__header__closeButton svg {
	cursor: pointer;
}

.BaseModal__mainContentWrapper__header__closeButton svg:hover {
	color: rgb(48, 65, 119);
}

/* ************************************* MAIN : START : Header Close Button Section ********************************** */

/* ************************************* MAIN : START : Header Details Section ********************************** */
.BaseModal__mainContentWrapper__header__details {
	align-items: center;
	display: flex;
	margin-bottom: 20px;
}

.BaseModal__mainContentWrapper__header__details__title h1 {
	font-family: 'Cairo', sans-serif;
	margin-bottom: 0;
	margin-top: 0;
}

.BaseModal__mainContentWrapper__header__details__subtitle p {
	font-family: 'Cairo', sans-serif;
	font-size: 0.9em;
	margin-top: 0;
}

.BaseModal__mainContentWrapper__header__details__right {
	justify-self: flex-end;
	margin-left: auto;
}

/* ************************************* MAIN : END : Header Details Section ********************************** */

/* ************************************* MAIN : START : AddReviewModalForm MaterialUI Inputs  ********************************** */

/* <FormLabel/> Components */
.MuiFormLabel-root {
	font-family: 'Cairo', sans-serif !important;
	margin-right: 10px;
}

/* Making all MaterialUI Input background whitish */
.AddReviewModalFormControls__inputs input,
.AddReviewModalFormControls__inputs .MuiInputBase-root {
	background: #f5f3f3;
	font-size: 0.9em;
}

/* ************************************* MAIN : END : AddReviewModalForm MaterialUI Inputs  ********************************** */

/* ************************************* MAIN : START : AddReviewModalForm All Inputs  ********************************** */
.AddReviewModalFormControls__inputs > div {
	margin-bottom: 15px;
}

.AddReviewModalFormControls__inputs__starValue {
	display: flex;
}

/* ************************************* MAIN : END: AddReviewModalForm All Inputs  ********************************** */

/* ************************************* MAIN : START : AddReviewModalForm Footer  ********************************** */
.AddReviewModalFormControls__footer {
	margin-top: 15px;
}

.AddReviewModalFormControls__footer__buttons {
	display: flex;
	justify-content: flex-end;
}

/* ************************************* MAIN : END : AddReviewModalForm Footer  ********************************** */

.AddReviewModalForm__header {
	color: gray;
	font-family: 'Cairo', sans-serif;
	font-size: 0.85em;
	font-style: italic;
	margin-bottom: 20px;
	margin-top: -20px;
}

/* ************************************* MAIN : START : Media Queries ********************************** */

/* Larger than 800px */
@media only screen and (min-width: 800px) {
	.AddReviewModal__BaseModal {
		min-width: 700px !important;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */

/* ************************************* MAIN : START : ReviewItems Showing Section ********************************** */
.MyReviewsPage__body__reviews {
	display: flex;
	height: 100%;
}

.MyReviewsPage__body__reviews__reviewItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : ReviewItems Showing Section ********************************** */

.DashboardPage {
	background: var(--pageBackgroundColor);
	display: flex;
	min-height: 100vh;
}

.DashboardPage > div {
	width: 100%;
}

.DashboardPage__body {
	display: flex;
	height: 100%;
	padding-top: 64px;
}

/* ************************************* MAIN : START : Dashboard Loader Screen ********************************** */
.DashboardPageLoader {
	align-items: center;
	display: flex;
	justify-content: center;
}

.DashboardPageLoader img {
	margin: 0 auto;
	max-width: 500px;
	padding: 20px;
	width: 100%;
}

/* ************************************* MAIN : END : Dashboard Loader Screen ********************************** */

/* ************************************* MAIN : START : Dashboard MainMenu ********************************** */
.DashboardPage__mainMenu {
	background: rgb(221, 212, 212);
	height: 100%;
}

.DashboardPage__mainMenu__header {
	background: var(--containerBackgroundColor);
	margin-bottom: -9px;
	padding: 10px 20px 20px 15px;
}

.DashboardPage__mainMenu__header img {
	max-width: 100%;
	padding: 10px;
}

.DashboardPage__mainMenu__header h1 {
	font-family: 'Oswald', sans-serif;
	font-size: 2.3em;
	margin: 0;
	margin-top: -10px;
	text-align: center;
}

.DashboardPage__mainMenu__links a {
	color: inherit;
	text-decoration: none;
}

/* ************************************* MAIN : END : Dashboard MainMenu ********************************** */

.DashboardPage__selectedPageContent {
	flex: 1 1;
	padding: 20px;
}

.DeveloperTestPage {
	margin: 20px;
}

/* ********************************************************** PasswordResetRequestFrom Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.PasswordResetRequestFormControls__inputs input,
.PasswordResetRequestFormControls__inputs .MuiInputBase-root {
	background: white;
}

.PasswordResetRequestFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************** SUB : PasswordResetRequestFrom Controls Footer - Reset Button ********************** */
.PasswordResetRequestFormControls__footer__resetButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.PasswordResetRequestFormControls__footer__resetButton[disabled] {
	background: rgb(157, 192, 147);
}

.PasswordResetRequestFormControls__footer__resetButton:hover {
	transform: scale(1.01);
}

.PasswordResetRequestFormControls__footer__resetButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.PasswordResetRequestFormControls input,
	.PasswordResetRequestFormControls__footer__resetButton {
		font-size: 0.8em;
	}
}

.ForgotPasswordPage {
	align-items: center;
	background: var(--pageBackgroundColor);
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.ForgotPasswordPage__container {
	background: var(--containerBackgroundColor);
	box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
	margin: 30px;
	padding: 20px;
	width: 500px;
}

/* ********************************************************** ForgotPasswordPage Header********************************************************** */
.ForgotPasswordPage__header {
	margin-bottom: 50px;
	text-align: center;
}

.ForgotPasswordPage__header img {
	max-width: 100px;
}

.ForgotPasswordPage__header h1 {
	color: rgb(36, 117, 192);
	font-family: 'Oswald', sans-serif;
	margin-top: 0;
}

.HomePage {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	text-align: center;
}

.HomePage__header img {
	max-width: 200px;
}

.HomePage__header h1 {
	color: rgb(35, 88, 112);
	font-family: 'Oswald', sans-serif;
	font-size: 3em;
	margin: 0;
}

.HomePage__buttons {
	display: flex;
}

.HomePage__buttons > a {
	border-radius: 4px;
	color: white;
	display: block;
	flex: 1 1;
	font-weight: 500;
	margin: 20px 10px;
	padding: 15px;
	text-decoration: none;
	text-transform: uppercase;
}

.HomePage__buttons > a:hover {
	transform: scale(1.02);
}

.HomePage__buttons__login {
	background: rgb(59, 93, 187);
}

.HomePage__buttons__signup {
	background: rgb(214, 95, 95);
}

/* ********************************************************** LinkUserToBusinessEmailEntryFormControls  Inputs Controls********************************************************** */

/* Making all MaterialUI Input background white */
.LinkUserToBusinessEmailEntryFormControls__inputs input,
.LinkUserToBusinessEmailEntryFormControls__inputs .MuiInputBase-root {
	background: white;
}

/* Adding bottom margin to every MaterialUI Input */
.LinkUserToBusinessEmailEntryFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************************************************** LinkUserToBusinessEmailEntryFormControls  Header ********************************************************** */
.LinkUserToBusinessEmailEntryFormControls__header {
	margin-bottom: 20px;
}

.LinkUserToBusinessEmailEntryFormControls__header p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

/* ********************************************************** LinkUserToBusinessEmailEntryFormControls  Footer ********************************************************** */

/* ********************** SUB : LinkUserToBusinessEmailEntryFormControlsFooter - Button ********************** */
.LinkUserToBusinessEmailEntryFormControls__footer__button {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.LinkUserToBusinessEmailEntryFormControls__footer__button[disabled] {
	background: rgb(157, 192, 147);
}

.LinkUserToBusinessEmailEntryFormControls__footer__button:hover {
	transform: scale(1.01);
}

.LinkUserToBusinessEmailEntryFormControls__footer__button__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.LinkUserToBusinessEmailEntryFormControls input,
	.LinkUserToBusinessEmailEntryFormControls__footer__LinkUserToBusinessEmailEntryFormControlsButton {
		font-size: 0.8em;
	}
}

.LinkUserToBusinessPage {
	align-items: center;
	background: var(--pageBackgroundColor);
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.LinkUserToBusinessPage__container {
	background: var(--containerBackgroundColor);
	box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
	margin: 30px;
	padding: 20px;
	width: 500px;
}

/* ************************************* MAIN : START : LinkUserToBusinessPage Header ********************************** */
.LinkUserToBusinessPage__header {
	margin-bottom: 50px;
	text-align: center;
}

.LinkUserToBusinessPage__header img {
	max-width: 100px;
}

.LinkUserToBusinessPage__header h1 {
	color: rgb(36, 117, 192);
	font-family: 'Oswald', sans-serif;
	margin-top: 0;
}

/* ************************************* MAIN : END : LinkUserToBusinessPage Header ********************************** */

/* ************************************* MAIN : START : LinkUserToBusinessPage Body ********************************** */

.LinkUserToBusinessPage__body__mainPageLoader {
	/* This is the loader we show when decrypting OrgId happens. */
	color: rgb(121, 116, 116);
	font-size: 0.85em;
	margin-bottom: 30px;
}

.LinkUserToBusinessPage__body__title {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.LinkUserToBusinessPage__body__title span {
	font-weight: bold;
}

.LinkUserToBusinessPage__body__businessUser,
.LinkUserToBusinessPage__body__userAlreadyExist {
	color: rgb(177, 91, 91);
	font-size: 0.85em;
	text-align: center;
}

.LinkUserToBusinessPage__body__reqPermission {
	color: rgb(121, 116, 116);
	font-size: 0.9em;

	/* font-size: 0.85em; */
}

.LinkUserToBusinessPage__body__reqPermission__loader {
	margin: 15px 0;
}

.LinkUserToBusinessPage__body__reqPermission__buttons {
	display: flex;
	justify-content: center;
}

.LinkUserToBusinessPage__body__reqPermission__buttons > button {
	margin-right: 10px;
}

/* ************************************* MAIN : END : LinkUserToBusinessPage Body ********************************** */

.LoginFormControls__flashMessage {
	background-color: rgba(171, 211, 125, 0.795);
	border-radius: 4px;
	font-size: 0.8em;
	padding: 10px 20px;
}

/* ********************************************************** LoginForm Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.LoginFormControls__inputs input,
.LoginFormControls__inputs .MuiInputBase-root {
	background: white;
}

.LoginFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************************************************** Signup Control Footer ********************************************************** */
.LoginFormControls__footer p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.LoginFormControls__footer a {
	color: rgb(36, 192, 109);
	padding: 0 5px;
	text-decoration: none;
}

.LoginFormControls__footer a:hover {
	text-decoration: underline;
}

/* ********************** SUB : Login Controls Footer - Login Button ********************** */
.LoginFormControls__footer__loginButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.LoginFormControls__footer__loginButton[disabled] {
	background: rgb(157, 192, 147);
}

.LoginFormControls__footer__loginButton:hover {
	transform: scale(1.01);
}

.LoginFormControls__footer__loginButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

.LoginFormControls__footer__links > div {
	display: flex;
}

.LoginFormControls__footer__links__forgotPassword {
	flex: 1 1;
	justify-self: flex-end;
	text-align: right;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.LoginFormControls input,
	.LoginFormControls__footer__loginButton {
		font-size: 0.8em;
	}

	.LoginFormControls__footer__links {
		flex-wrap: wrap;
	}

	.LoginFormControls__footer__links > p {
		flex-basis: 100%;
		margin: 10px 0 0 0;
		padding: 0;
	}

	.LoginFormControls__footer__links__forgotPassword {
		flex: 1 1;
		justify-self: flex-start;
		text-align: left;
	}
}

.LoginPage {
	align-items: center;
	background: var(--pageBackgroundColor);
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.LoginPage__container {
	background: var(--containerBackgroundColor);
	box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
	margin: 30px;
	padding: 20px;
	width: 500px;
}

/* ********************************************************** LoginPage Header********************************************************** */
.LoginPage__header {
	margin-bottom: 50px;
	text-align: center;
}

.LoginPage__header img {
	max-width: 100px;
}

.LoginPage__header h1 {
	color: rgb(36, 117, 192);
	font-family: 'Oswald', sans-serif;
	margin-top: 0;
}

/* ********************************************************** Signup Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.SignupEmailConfFormControls__inputs input,
.SignupEmailConfFormControls__inputs .MuiInputBase-root {
	background: white;
}

/* Adding bottom margin to every MaterialUI Input */
.SignupEmailConfFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************************************************** Signup Control Header ********************************************************** */
.SignupEmailConfFormControls__header {
	margin-bottom: 20px;
}

.SignupEmailConfFormControls__header p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.SignupEmailConfFormControls__header a {
	color: rgb(36, 192, 109);
}

.SignupEmailConfFormControls__header span {
	font-weight: bold;
}

.SignupEmailConfFormControls__header__changeEmail {
	margin-top: -10px;
}

/* ********************************************************** Signup Control Footer ********************************************************** */

/* ********************** SUB : Signup Control Footer - Signup Button ********************** */
.SignupEmailConfFormControls__footer__signupButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.SignupEmailConfFormControls__footer__signupButton[disabled] {
	background: rgb(157, 192, 147);
}

.SignupEmailConfFormControls__footer__signupButton:hover {
	transform: scale(1.01);
}

.SignupEmailConfFormControls__footer__signupButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.SignupEmailConfFormControls input,
	.SignupEmailConfFormControls__footer__signupButton {
		font-size: 0.8em;
	}
}

/* ********************************************************** Signup Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.SignupEmailEntryFormControls__inputs input,
.SignupEmailEntryFormControls__inputs .MuiInputBase-root {
	background: white;
}

/* Adding bottom margin to every MaterialUI Input */
.SignupEmailEntryFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************************************************** Signup Control Header ********************************************************** */
.SignupEmailEntryFormControls__header {
	margin-bottom: 20px;
}

.SignupEmailEntryFormControls__header p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

/* ********************************************************** Signup Control Footer ********************************************************** */

/* ********************** SUB : Signup Control Footer - Signup Button ********************** */
.SignupEmailEntryFormControls__footer__signupButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.SignupEmailEntryFormControls__footer__signupButton[disabled] {
	background: rgb(157, 192, 147);
}

.SignupEmailEntryFormControls__footer__signupButton:hover {
	transform: scale(1.01);
}

.SignupEmailEntryFormControls__footer__signupButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.SignupEmailEntryFormControls input,
	.SignupEmailEntryFormControls__footer__signupButton {
		font-size: 0.8em;
	}
}

/* ********************************************************** Signup Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.SignupFormControls__inputs input,
.SignupFormControls__inputs .MuiInputBase-root,
.SignupFormControls__inputs .MuiSelect-root {
	background: white;
}

.SignupFormControls__flashMessage {
	background-color: rgba(171, 211, 125, 0.795);
	border-radius: 4px;
	font-size: 0.8em;
	padding: 10px 20px;
}

/* ********************** SUB : Signup Control Inputs - Grid Nested Input Bug Fix ********************** */

/* Fixing Input Controls Spacing Problem with Grid Items in Mobile Resolutions */
.SignupFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

.SignupFormControls__inputs .MuiGrid-spacing-xs-1 > .MuiGrid-item {
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}

.SignupFormControls__inputs .MuiGrid-spacing-xs-1 {
	margin-bottom: 0 !important;
	margin-top: 0 !important;
}

.SignupFormControls__inputs__countrySelect {
	position: relative;
}

/* ********************** SUB : Signup Control Inputs - Customized Select Placeholder ********************** */

/* Adding customized Placeholder for Country Selector to match with MateriaUI. As MaterialUI Select Placeholder mess things up. */
.SignupFormControls__inputs__countrySelect__customPlaceholder {
	bottom: 0;
	color: #aaa;
	left: 45px;
	position: absolute;
	top: 0;
	transform: translateY(30%);
	z-index: 100;
}

/* ********************************************************** Signup Control Footer ********************************************************** */
.SignupFormControls__footer p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.SignupFormControls__footer a {
	color: rgb(36, 192, 109);
	padding: 0 5px;
	text-decoration: none;
}

.SignupFormControls__footer a:hover {
	text-decoration: underline;
}

/* ********************** SUB : Signup Control Footer - Signup Button ********************** */
.SignupFormControls__footer__signupButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.SignupFormControls__footer__signupButton[disabled] {
	background: rgb(157, 192, 147);
}

.SignupFormControls__footer__signupButton:hover {
	transform: scale(1.01);
}

.SignupFormControls__footer__signupButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

.SignupFormControls__footer__acknowledgeParagraph {
	color: gray !important;
	text-align: center;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.SignupFormControls input,
	.SignupFormControls .MuiSelect-root,
	.SignupFormControls__inputs__countrySelect__customPlaceholder,
	.SignupFormControls__footer__signupButton {
		font-size: 0.8em;
	}
}

.SignupPage {
	align-items: center;
	background: var(--pageBackgroundColor);
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.SignupPage__container {
	background: var(--containerBackgroundColor);
	box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
	margin: 30px;
	max-width: 500px;
	padding: 20px;
}

/* ********************************************************** SignupPage Header********************************************************** */
.SignupPage__header {
	margin-bottom: 20px;
	text-align: center;
}

.SignupPage__header img {
	max-width: 100px;
}

.SignupPage__header h1 {
	color: rgb(36, 117, 192);
	font-family: 'Oswald', sans-serif;
	margin-top: 0;
}

.SignupPage__header__stepper {
	background: none !important;
}

.Unauthorized {
	align-items: center;
	background: rgb(201, 71, 71);
	display: flex;
	font-family: ;
	font-family: 'Cairo', sans-serif;
	justify-content: center;
	min-height: 100vh;
	padding: 1px;
	padding: 20px;
	text-align: center;
}

.Unauthorized svg {
	color: white;
	font-size: 10em;
}

.Unauthorized h1 {
	color: white;
	text-transform: uppercase;
}

/* NOTE :
      - "GC-DP" stands for "GlobalClass-DashboardPage"
*/

.GC-DP-DashboardPage {
	font-family: 'Cairo', sans-serif;
	height: 100%;
}

.GC-DP-DashboardPage > div {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.GC-DP-DashboardPageHeader h1 {
	color: #003049;
	margin: 0;
	margin-bottom: 15px;
}

.GC-DP-DashboardPageBody {
	background: white;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	padding: 25px 25px;
}

.GC-DP-DashboardPage__LocationSelectorWrapper {
	border-bottom: 1px solid rgb(219, 208, 208);
	padding-bottom: 20px;
}

:root {
	--GC-DP-VAR-formFontSize: 1.9em;
}

/* ************************************* MAIN : START : Special State Styles Applied to FormikTextFields ********************************** */
.GC-DP-FormikField--editable {
	background: rgba(116, 226, 184, 0.158);
	padding: 5px !important;
}

.GC-DP-FormikField--nonEditable .MuiInputBase-root::before {
	border: 0;
}

.GC-DP-FormikField--nonEditable .MuiInputBase-root input,
.GC-DP-FormikField--nonEditable .MuiInputBase-root .MuiSelect-root {
	color: black;
}

/* ************************************* MAIN : END : Special State Styles Applied to FormikTextFields ********************************** */

/* NOTE :
      - "GC" in ClassNames indicate 'Global Class'
*/

.Toastify__toast-container {
	/* background: #41ac9e; */
}

.Toastify__toast-container--top-right {
	border-radius: 10px 0 0 10px;
	padding: 15px;
	padding-bottom: 0;
	padding-right: 0;
	right: 0;
	top: 2em;
}

.Toastify__toast {
	border-radius: 4px 0 0 4px;
	color: rgba(0, 0, 0, 0.699);
	font-family: 'Cairo', sans-serif;
	font-size: 0.85em;
	min-height: 40px;
	padding: 0 15px;
}

.Toastify__toast--dark {
	background: #073b4c;
	color: white;
}
.Toastify__toast--default {
	background: #ecf1e7;
}
.Toastify__toast--info {
	background: #66dbff;
}
.Toastify__toast--success {
	background: #ac0;
}
.Toastify__toast--warning {
	background: #ffd166;
}
.Toastify__toast--error {
	background: rgb(255, 120, 102);
	color: rgb(51, 51, 59);
}




