.LocationSelector__selectorsWrapper__headerInfo {
	font-weight: 500;
}

.LocationSelector__selectorsWrapper__selectors {
	display: flex;
	font-size: 0.9em;
	margin-top: 15px;
}

.LocationSelector__selectorsWrapper__selectors > div {
	flex: 1;
	margin-right: 5px;
}

/* ************************************* MAIN : START : Media Queries********************************** */
@media only screen and (max-width: 600px) {
	.LocationSelector__selectorsWrapper__selectors {
		flex-wrap: wrap;
	}

	.LocationSelector__selectorsWrapper__selectors > div {
		flex-basis: 100%;
		margin-bottom: 5px;
		margin-right: 0;
	}

	.LocationSelector__selectorsWrapper__selectors button {
		margin-left: auto;
	}
}

/* ************************************* MAIN : END : Media Queries********************************** */
