.BaseModal {
	font-family: 'Cairo', sans-serif;
}

/* ************************************* MAIN : START : Header Close Button Section ********************************** */
.BaseModal__mainContentWrapper__header__closeButton {
	margin-left: auto;
	padding-bottom: 5px;
	text-align: right;
}

.BaseModal__mainContentWrapper__header__closeButton svg {
	cursor: pointer;
}

.BaseModal__mainContentWrapper__header__closeButton svg:hover {
	color: rgb(48, 65, 119);
}

/* ************************************* MAIN : START : Header Close Button Section ********************************** */

/* ************************************* MAIN : START : Header Details Section ********************************** */
.BaseModal__mainContentWrapper__header__details {
	align-items: center;
	display: flex;
	margin-bottom: 20px;
}

.BaseModal__mainContentWrapper__header__details__title h1 {
	font-family: 'Cairo', sans-serif;
	margin-bottom: 0;
	margin-top: 0;
}

.BaseModal__mainContentWrapper__header__details__subtitle p {
	font-family: 'Cairo', sans-serif;
	font-size: 0.9em;
	margin-top: 0;
}

.BaseModal__mainContentWrapper__header__details__right {
	justify-self: flex-end;
	margin-left: auto;
}

/* ************************************* MAIN : END : Header Details Section ********************************** */
