.LoyaltySchemeSettingsPage__body__formikContainer {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.LoyaltySchemeSettingsPage__body__formikContainer__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : No Settings View ********************************** */

.LoyaltySchemeSettingsPage__body__formikContainer__noSettings__selectedItem {
	font-weight: 600;
	margin-top: 5px;
}

.LoyaltySchemeSettingsPage__body__formikContainer__noSettings svg {
	font-size: 75px;
}

.LoyaltySchemeSettingsPage__body__formikContainer__noSettings__addButton {
	cursor: pointer;
}

/* ************************************* MAIN : END : No Settings View ********************************** */

/* ************************************* MAIN : START : Add/Update Settings Form ********************************** */
.LoyaltySchemeSettingsPage__body__settingsForm {
	font-size: 0.9em;
	margin: 0 auto;
	max-width: 600px;
	padding-top: 20px;
}

.LoyaltySchemeSettingsPage__body__settingsForm .MuiInputBase-root {
	font-size: 0.9em;
}

.LoyaltySchemeSettingsPage__body__settingsForm__header {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}

.LoyaltySchemeSettingsPage__body__settingsForm__header button {
	margin-left: 5px;
}

.LoyaltySchemeSettingsPage__body__settingsForm__formControls__description {
	color: rgb(116, 109, 109);
	font-size: 0.95em;
	font-weight: 600;
	padding-bottom: 5px;
}

/* ************************************* MAIN : END : Settings Form ********************************** */
