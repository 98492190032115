.Unauthorized {
	align-items: center;
	background: rgb(201, 71, 71);
	display: flex;
	font-family: ;
	font-family: 'Cairo', sans-serif;
	justify-content: center;
	min-height: 100vh;
	padding: 1px;
	padding: 20px;
	text-align: center;
}

.Unauthorized svg {
	color: white;
	font-size: 10em;
}

.Unauthorized h1 {
	color: white;
	text-transform: uppercase;
}
