.CustomerCheckInsPage__body__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : CheckInItems Showing Section ********************************** */
.CustomerCheckInsPage__body__checkIns {
	display: flex;
	height: 100%;
}

.CustomerCheckInsPage__body__checkIns__checkInItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : CheckInItems Showing Section ********************************** */
