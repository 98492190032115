/* NOTE :
      - "GC-DP" stands for "GlobalClass-DashboardPage"
*/

.GC-DP-DashboardPage {
	font-family: 'Cairo', sans-serif;
	height: 100%;
}

.GC-DP-DashboardPage > div {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.GC-DP-DashboardPageHeader h1 {
	color: #003049;
	margin: 0;
	margin-bottom: 15px;
}

.GC-DP-DashboardPageBody {
	background: white;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 25px 25px;
}

.GC-DP-DashboardPage__LocationSelectorWrapper {
	border-bottom: 1px solid rgb(219, 208, 208);
	padding-bottom: 20px;
}

:root {
	--GC-DP-VAR-formFontSize: 1.9em;
}

/* ************************************* MAIN : START : Special State Styles Applied to FormikTextFields ********************************** */
.GC-DP-FormikField--editable {
	background: rgba(116, 226, 184, 0.158);
	padding: 5px !important;
}

.GC-DP-FormikField--nonEditable .MuiInputBase-root::before {
	border: 0;
}

.GC-DP-FormikField--nonEditable .MuiInputBase-root input,
.GC-DP-FormikField--nonEditable .MuiInputBase-root .MuiSelect-root {
	color: black;
}

/* ************************************* MAIN : END : Special State Styles Applied to FormikTextFields ********************************** */
