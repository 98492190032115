.CustomerCheckInItemCard {
	background: #f0efeb;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	font-size: 0.9em;
	margin-bottom: 10px;
}

/* ************************************* MAIN : START : Logo Section ********************************** */
.CustomerCheckInItemCard__logo {
	background: #4e678a;
	display: flex;
	justify-self: center;
	width: 50px;
}

.CustomerCheckInItemCard__logo svg {
	color: rgb(100, 98, 98);
	color: white;
	height: 100%;
	margin: 0 auto;
	max-width: 50px;
	width: 100%;
}

/* ************************************* MAIN : END : Logo Section ********************************** */

/* ************************************* MAIN : START :  Details Section ********************************** */
.CustomerCheckInItemCard__details {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 15px 20px;
}

/* **************** SUBSECTION : START : CheckIn Specific Details **************** */
.CustomerCheckInItemCard__details > div {
	padding-bottom: 5px;
}

.CustomerCheckInItemCard__details__title {
	font-weight: bold;
}

.CustomerCheckInItemCard__details__desc {
	font-style: italic;
}

.CustomerCheckInItemCard__details__time {
	margin-left: auto;
	white-space: nowrap;
}

/* **************** SUBSECTION : END : CheckIn Specific Details **************** */

.CustomerCheckInItemCard__details__bottom {
	align-items: flex-end;
	color: rgb(73, 71, 71);
	display: flex;
	height: 100%;
}

/* **************** SUBSECTION : START : Customer Specific Details **************** */

.CustomerCheckInItemCard__details__customerDetails {
	align-items: center;
	display: flex;
	margin-bottom: -5px;
}

.CustomerCheckInItemCard__details__customerDetails__name {
	white-space: nowrap;
}

.CustomerCheckInItemCard__details__customerDetails span {
	align-items: center;
	display: inline-flex;
	margin-right: 10px;
}

.CustomerCheckInItemCard__details__customerDetails span svg {
	font-size: 20px;
	margin-right: 5px;
}

/* **************** SUBSECTION : END: Customer Specific  Details **************** */

/* ************************************* MAIN : END : Details Section ********************************** */

/* ************************************* MAIN : START : Points Section ********************************** */
.CustomerCheckInItemCard__points {
	align-items: center;
	display: flex;
	font-size: 0.8em;
	font-weight: bold;
	justify-content: center;
	min-width: 50px;
	padding: 15px;
	text-align: center;
}

.CustomerCheckInItemCard__points__pointValue {
	align-items: center;
	background: #294a79;
	border-radius: 50%;
	color: white;
	display: flex;
	height: 60px;
	justify-content: center;
	margin: 10px;
	width: 60px;
}

.CustomerCheckInItemCard__points__totalUserPointValue {
	padding: 5px;
}

/* ************************************* MAIN : END : Points Section ********************************** */

/* ************************************* MAIN : START : Media Queries ********************************** */

@media only screen and (max-width: 600px) {
	.CustomerCheckInItemCard {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard__details__bottom {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard__details__customerDetails {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard__details__time {
		margin: 0;
		margin-top: 15px;
	}
}

@media only screen and (max-width: 500px) {
	.CustomerCheckInItemCard {
		flex-wrap: wrap;
	}

	.CustomerCheckInItemCard > div {
		flex-basis: 100%;
	}

	.CustomerCheckInItemCard__logo {
		padding: 5px 0;
		text-align: center;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */
