/* ********************************************************** PasswordResetRequestFrom Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.PasswordResetRequestFormControls__inputs input,
.PasswordResetRequestFormControls__inputs .MuiInputBase-root {
	background: white;
}

.PasswordResetRequestFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************** SUB : PasswordResetRequestFrom Controls Footer - Reset Button ********************** */
.PasswordResetRequestFormControls__footer__resetButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.PasswordResetRequestFormControls__footer__resetButton[disabled] {
	background: rgb(157, 192, 147);
}

.PasswordResetRequestFormControls__footer__resetButton:hover {
	transform: scale(1.01);
}

.PasswordResetRequestFormControls__footer__resetButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.PasswordResetRequestFormControls input,
	.PasswordResetRequestFormControls__footer__resetButton {
		font-size: 0.8em;
	}
}
