.PageLoader {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	text-align: center;
}

.PageLoader__info {
	align-items: center;
	display: flex;
}

.PageLoader__info__error {
	color: red;
}

.PageLoader__info__error__buttons {
	margin-top: 15px;
}
