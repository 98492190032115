.CustomerLoyaltyAwardsPage__body__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : AwardsItems Showing Section ********************************** */
.CustomerLoyaltyAwardsPage__body__loyaltyAwards {
	display: flex;
	height: 100%;
}

.CustomerLoyaltyAwardsPage__body__loyaltyAwards__awardsItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : AwardsItems Showing Section ********************************** */
