.DashboardItemCard {
	background: rgb(255, 255, 255);
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
	display: flex;
	flex-direction: column;
	padding: 10px;
}

.DashboardItemCard__header {
	border-bottom: 1px rgba(128, 128, 128, 0.123) solid;
	color: rgb(107, 106, 106);
	font-family: 'Cairo', sans-serif;
	font-weight: bold;
	padding: 10px 10px;
}

.DashboardItemCard__body {
	flex: 1;
	margin: 10px;
}

.DashboardItemCard__footer {
	padding: 0 10px;
}

/* ************************************* MAIN : START : Helper Component : DashboardItemCardFooter ********************************** */

.DashboardItemCardFooter {
	align-items: center;
	display: flex;
	font-size: 0.8em;
	justify-content: flex-end;
}

.DashboardItemCardFooter a {
	color: rgb(38, 103, 133);
	font-weight: bold;
	text-decoration: none;
}

.DashboardItemCardFooter svg {
	color: rgb(38, 103, 133);
}

/* ************************************* MAIN : END : Helper Component : DashboardItemCardFooter ********************************** */
