.DashboardSubInfoSection {
	align-items: center;
	color: gray;
	display: flex;
	flex: 1;
	font-size: 0.8em;
	height: 100%;
	justify-content: center;
	margin: 0 auto;
	text-align: center;
	width: 100%;
}

.DashboardSubInfoSection svg {
	font-size: 100px;
}
