.CustomerDetailsPage__body__formikContainer {
	margin: 0 auto;
}

.CustomerDetailsPage__body__formikContainer__form {
	font-size: 0.9em;
}

.CustomerDetailsPage__body__formikContainer__header__options {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
}
