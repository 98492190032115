.LinkUserToBusinessPage {
	align-items: center;
	background: var(--pageBackgroundColor);
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.LinkUserToBusinessPage__container {
	background: var(--containerBackgroundColor);
	box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
	margin: 30px;
	padding: 20px;
	width: 500px;
}

/* ************************************* MAIN : START : LinkUserToBusinessPage Header ********************************** */
.LinkUserToBusinessPage__header {
	margin-bottom: 50px;
	text-align: center;
}

.LinkUserToBusinessPage__header img {
	max-width: 100px;
}

.LinkUserToBusinessPage__header h1 {
	color: rgb(36, 117, 192);
	font-family: 'Oswald', sans-serif;
	margin-top: 0;
}

/* ************************************* MAIN : END : LinkUserToBusinessPage Header ********************************** */

/* ************************************* MAIN : START : LinkUserToBusinessPage Body ********************************** */

.LinkUserToBusinessPage__body__mainPageLoader {
	/* This is the loader we show when decrypting OrgId happens. */
	color: rgb(121, 116, 116);
	font-size: 0.85em;
	margin-bottom: 30px;
}

.LinkUserToBusinessPage__body__title {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.LinkUserToBusinessPage__body__title span {
	font-weight: bold;
}

.LinkUserToBusinessPage__body__businessUser,
.LinkUserToBusinessPage__body__userAlreadyExist {
	color: rgb(177, 91, 91);
	font-size: 0.85em;
	text-align: center;
}

.LinkUserToBusinessPage__body__reqPermission {
	color: rgb(121, 116, 116);
	font-size: 0.9em;

	/* font-size: 0.85em; */
}

.LinkUserToBusinessPage__body__reqPermission__loader {
	margin: 15px 0;
}

.LinkUserToBusinessPage__body__reqPermission__buttons {
	display: flex;
	justify-content: center;
}

.LinkUserToBusinessPage__body__reqPermission__buttons > button {
	margin-right: 10px;
}

/* ************************************* MAIN : END : LinkUserToBusinessPage Body ********************************** */
