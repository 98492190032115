.DashboardHomeBis {
	height: 100%;
}

/* ************************************* MAIN : START : DashboardHome Header Items  ************************************* */

.DashboardHomeBis__header {
	display: flex;
	margin-bottom: 20px;
	padding: 10px 0;
}

.DashboardHomeBis__header__right {
	align-items: center;
	display: none;

	/* display: flex; */
	margin-left: auto;
}

/* ***************** SUB : START : UserMenu Section ***************** */

.DashboardHomeBis__header__userMenu {
	margin-left: 10px;
}

.DashboardHomeBis__header__userMenu svg {
	cursor: pointer;
	font-size: 50px;
}

/* ***************** SUB : END : UserMenu Section ***************** */

/* ************************************* MAIN : END : DashboardHome Header Items  ************************************* */

/* ************************************* MAIN : START : DashboardHome Body  ************************************* */

/* ***************** SUB : START : Welcome Section ***************** */
.DashboardHomeBis__body__welcome {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	margin-top: 200px;
	text-align: center;
}

.DashboardHomeBis img {
	max-width: 300px;
}

.DashboardHomeBis h1 {
	color: rgb(66, 57, 57);
	font-size: 2.4em;
}

.DashboardHomeBis h2 {
	color: gray;
	font-size: 0.9em;
	font-weight: normal;
	margin: 0;
	margin-top: -15px;
}

/* ***************** SUB : END : TotalPoints Section ***************** */

/* ************************************* MAIN : END : DashboardHome Body  ************************************* */
