.LoginPage {
	align-items: center;
	background: var(--pageBackgroundColor);
	display: flex;
	justify-content: center;
	min-height: 100vh;
}

.LoginPage__container {
	background: var(--containerBackgroundColor);
	box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
	margin: 30px;
	padding: 20px;
	width: 500px;
}

/* ********************************************************** LoginPage Header********************************************************** */
.LoginPage__header {
	margin-bottom: 50px;
	text-align: center;
}

.LoginPage__header img {
	max-width: 100px;
}

.LoginPage__header h1 {
	color: rgb(36, 117, 192);
	font-family: 'Oswald', sans-serif;
	margin-top: 0;
}
