.CustomerDetailsPageFormControls .MuiFormControl-root input,
.CustomerDetailsPageFormControls .MuiFormControl-root .MuiSelect-root {
	font-size: 0.85em;
}

/* ************************************* MAIN : START : Groups of "<CustomerDetailsPageInfoItem/>" ********************************** */
.CustomerDetailsPageFormControls__infoContainer {
	margin: 0 auto;
}

.CustomerDetailsPageFormControls__infoItemsGroup {
	margin-bottom: 40px;
}

.CustomerDetailsPageFormControls__infoItemsGroup--sub {
	margin-bottom: 0;
}

/* ************************************* MAIN : END : Groups of "<CustomerDetailsPageInfoItem/>" ********************************** */

/* ************************************* MAIN : START : Custom Styles for AddressGroups ********************************** */
.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup {
}

.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup
	.MuiAccordion-root {
	background: #fffafa33;
}

.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup
	.MuiAccordionDetails-root {
	display: block;
}

.CustomerDetailsPageFormControls__infoItemsGroup__addressGroup__title {
	font-style: italic;
	font-weight: 500;
}

/* ************************************* MAIN : END : Custom Styles for AddressGroups ********************************** */
