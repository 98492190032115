/* ************************************* MAIN : START : QR Code ********************************** */
.QrCodeGeneratorPage__qrCodeWrapper {
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;
	text-align: center;
}

.QrCodeGeneratorPage__qrCodeWrapper__qr canvas {
	max-width: 500px;
	width: 100%;
}

.QrCodeGeneratorPage__qrCodeWrapper__buttons {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.QrCodeGeneratorPage__qrCodeWrapper__buttons__download {
	color: #3f51b5;
	cursor: pointer;
	font-weight: bold;
	text-align: center;
}

.QrCodeGeneratorPage__qrCodeWrapper__buttons__download svg {
	font-size: 50px;
}

/* ************************************* MAIN : END : QR Code ********************************** */

/* ************************************* MAIN : START : Specific Loading/Error Cases Showing ********************************** */
.QrCodeGeneratorPage__caseHandling {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
}

.QrCodeGeneratorPage__caseHandling:empty {
	flex: 0 !important;
}

.QrCodeGeneratorPage__caseHandling__getStarted svg {
	font-size: 200px;
}

/* ************************************* MAIN : END : Specific Loading/Error Cases Showing ********************************** */
