/* ************************************* MAIN : START : Media Queries ********************************** */

/* Larger than 800px */
@media only screen and (min-width: 800px) {
	.AddReviewModal__BaseModal {
		min-width: 700px !important;
	}
}

/* ************************************* MAIN : END : Media Queries ********************************** */
