.LoginFormControls__flashMessage {
	background-color: rgba(171, 211, 125, 0.795);
	border-radius: 4px;
	font-size: 0.8em;
	padding: 10px 20px;
}

/* ********************************************************** LoginForm Control Inputs ********************************************************** */

/* Making all MaterialUI Input background white */
.LoginFormControls__inputs input,
.LoginFormControls__inputs .MuiInputBase-root {
	background: white;
}

.LoginFormControls__inputs .MuiFormControl-root {
	margin-bottom: 10px;
}

/* ********************************************************** Signup Control Footer ********************************************************** */
.LoginFormControls__footer p {
	color: rgb(121, 116, 116);
	font-size: 0.85em;
}

.LoginFormControls__footer a {
	color: rgb(36, 192, 109);
	padding: 0 5px;
	text-decoration: none;
}

.LoginFormControls__footer a:hover {
	text-decoration: underline;
}

/* ********************** SUB : Login Controls Footer - Login Button ********************** */
.LoginFormControls__footer__loginButton {
	background: rgb(36, 192, 109);
	border: none;
	border-radius: 4px;
	color: white;
	cursor: pointer;
	font-weight: bold;
	height: 50px;
	margin: 5px 0;
	position: relative;
	width: 100%;
}

.LoginFormControls__footer__loginButton[disabled] {
	background: rgb(157, 192, 147);
}

.LoginFormControls__footer__loginButton:hover {
	transform: scale(1.01);
}

.LoginFormControls__footer__loginButton__progress {
	position: absolute;
	right: 20px;
	top: 15px;
}

.LoginFormControls__footer__links > div {
	display: flex;
}

.LoginFormControls__footer__links__forgotPassword {
	flex: 1;
	justify-self: flex-end;
	text-align: right;
}

/* ********************************************************** Media Queries ********************************************************** */

/* Matching MaterialUI 'xs' breakpoint. */
@media only screen and (max-width: 600px) {
	.LoginFormControls input,
	.LoginFormControls__footer__loginButton {
		font-size: 0.8em;
	}

	.LoginFormControls__footer__links {
		flex-wrap: wrap;
	}

	.LoginFormControls__footer__links > p {
		flex-basis: 100%;
		margin: 10px 0 0 0;
		padding: 0;
	}

	.LoginFormControls__footer__links__forgotPassword {
		flex: 1;
		justify-self: flex-start;
		text-align: left;
	}
}
