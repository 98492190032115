/* ************************************* MAIN : START : Total Loyalty Points Showing Section ********************************** */
.MyLoyaltyPointsPage__body__totalLoyaltyPoints {
	display: flex;
	height: 100%;
}

.MyLoyaltyPointsPage__body__totalLoyaltyPoints__totalPointItem {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : Total Loyalty Points Showing Section ********************************** */

/* ************************************* MAIN : START : HELPER COMPONENT : TotalLoyaltyPointItemCard ********************************** */
.TotalLoyaltyPointItemCard {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	margin-bottom: 10px;
	max-width: 400px;
}

.TotalLoyaltyPointItemCard__locationName {
	background: #264653;
	border-radius: 4px 0 0 4px;
	color: white;
	flex: 2;
	padding: 15px;
}

.TotalLoyaltyPointItemCard__locationPoints {
	background: #d9dfe1;
	border-radius: 0 4px 4px 0;
	flex: 1;
	min-width: 50px;
	padding: 15px;
	text-align: right;
}

/* ************************************* MAIN : END : HELPER COMPONENT : TotalLoyaltyPointItemCard ********************************** */
