.CustomerReviewsPage__body__subPageActionLoader {
	height: 100%;
}

/* ************************************* MAIN : START : ReviewItems Showing Section ********************************** */
.CustomerReviewsPage__body__reviews {
	display: flex;
	height: 100%;
}

.CustomerReviewsPage__body__reviews__reviewItems {
	height: 100%;
	margin: 0 auto;
	margin-top: 20px;
	max-width: 900px;
	width: 100%;
}

/* ************************************* MAIN : END : ReviewItems Showing Section ********************************** */
