.DashboardHome {
	font-family: 'Cairo', sans-serif;
	height: 100%;
}

/* ************************************* MAIN : START : DashboardHome Header Items  ************************************* */

.DashboardHome__header {
	display: flex;
	margin-bottom: 20px;
	padding: 10px 0;
}

.DashboardHome__header__left {
	align-items: center;
	display: flex;
	font-size: 0.85em;
	font-weight: 500;
}

.DashboardHome__header__right {
	align-items: center;
	display: flex;
	margin-left: auto;
}

/* ***************** SUB : START : CurrentLinkedLocation Section ***************** */
.DashboardHome__header__currentLinkedLocation {
	align-items: center;
	background: #a8dadc;
	border-radius: 0 4px 4px 0;
	display: flex;
	text-align: center;
}

.DashboardHome__header__currentLinkedLocation__title {
	background: #2a9d8f;
	border-radius: 4px 0 0 4px;
	color: white;
	padding: 10px;
	text-align: right;
}

.DashboardHome__header__currentLinkedLocation__value {
	padding: 10px;
}

/* ***************** SUB : END : CurrentLinkedLocation Section ***************** */

/* ***************** SUB : START : TotalPoints Section ***************** */

.DashboardHome__header__totalPoints {
	font-size: 0.85em;
	font-weight: 500;
}

.DashboardHome__header__totalPoints__title {
	background: #2a9d8f;
	border-radius: 4px 0 0 4px;
	color: white;
	padding: 10px;
}

.DashboardHome__header__totalPoints__value {
	background: #a8dadc;
	border-radius: 0 4px 4px 0;
	padding: 10px;
}

/* ***************** SUB : END : TotalPoints Section ***************** */

/* ***************** SUB : START : UserMenu Section ***************** */

.DashboardHome__header__userMenu {
	margin-left: 10px;
}

.DashboardHome__header__userMenu svg {
	cursor: pointer;
	font-size: 50px;
}

/* ***************** SUB : END : UserMenu Section ***************** */

/* ************************************* MAIN : END : DashboardHome Header Items  ************************************* */

.DashboardHome__body__greetings {
	color: #003049;
}

.DashboardHome__body__cards {
	display: grid;
	gap: 40px;
	grid-auto-rows: minmax(300px, auto);
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}

/* ************************************* MAIN : START : Media Queries  ************************************* */
@media only screen and (max-width: 600px) {
	.DashboardHome__body__cards {
		grid-template-columns: repeat(1, 1fr);
	}
}

/* ************************************* MAIN : END : Media Queries /* ************************************* */
